import {Popover, Tooltip} from '/js/vendor/bootstrap.custom.js';
import {Fancybox} from '/js/vendor/fancybox.custom.js';
import {ru} from '/js/vendor/fancybox.ru.esm.js';
import {Swiper, Navigation, FreeMode} from '/js/vendor/swiper.custom.js';


Fancybox.bind("[data-fancybox]", {
    l10n: ru,
    contentDblClick: "zoomToFit",
    dragToClose: () => window.matchMedia('screen and (max-width: 768px)').matches,
});


const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
popoverTriggerList.forEach(popoverTriggerEl => new Popover(popoverTriggerEl))

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
tooltipTriggerList.forEach(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))


document.addEventListener('click', (e) => {
    let btn = e.target.closest('.text-clamped__more')

    if (btn) {
        e.preventDefault()
        let target = document.getElementById(btn.getAttribute('aria-controls'))

        if (target) {
            target.classList.toggle('text-clamped')
            btn.setAttribute('aria-expanded', !btn.getAttribute('aria-expanded') === 'true')
            btn.blur()
            /*
                    let clientTop = target.getBoundingClientRect().top

                    if (target.classList.contains('text-clamped')) {
                        if (clientTop < 80) target.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                    else {
                        if (clientTop > 2*window.innerHeight/3) target.scrollIntoView({ behavior: "smooth", block: "center" });;
                    } */
        }
    }
})

document.addEventListener('click', (e) => {
    let btn = e.target.closest('.text-collapsed__more')

    if (btn) {
        e.preventDefault()
        let target = document.getElementById(btn.getAttribute('aria-controls'))

        if (target) {
            target.classList.toggle('text-collapsed')
            btn.setAttribute('aria-expanded', !btn.getAttribute('aria-expanded') === 'true')
            btn.blur()
        }
    }
})


let lazySliders = document.querySelectorAll('[data-swiper-lazyload]')

if (lazySliders.length) {

    const attachSwiper = (entries) => {
        entries.forEach(entry => {
            var element = entry.target

            if (entry.isIntersecting && !element.classList.contains('swiper-initialized')) {
                let swiper = new Swiper(element, {
                    modules: [Navigation, FreeMode],
                    slidesPerView: 'auto',
                    slidesPerGroupAuto: true,
                    spaceBetween: 20,
                    grabCursor: true,
                    noSwipingClass: 'card-img',
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    breakpoints: {
                        575.98: {
                            spaceBetween: 24,
                        },
                        850: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                        1300: {
                            slidesPerView: 5,
                        },
                        1600: {
                            slidesPerView: 6,
                        },
                    }
                });
            }
        })
    }

    let observer = new IntersectionObserver(attachSwiper, {
        rootMargin: "10% 1%",
        threshold: 0.1,
    });

    lazySliders.forEach(el => observer.observe(el))
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

document.addEventListener('DOMContentLoaded', function (event) {
    let btns = document.querySelectorAll('.btn-primary:not([type])');

    if (btns) {
        let one_id = getRandomInt(btns.length - 1)

        let span_one = document.createElement('span');
        span_one.className = "btn-insect";
        btns[one_id].append(span_one);
        btns[one_id].classList.add("btn-animated");
        btns[one_id].addEventListener('mouseover', function () {
            btns[one_id].classList.add("btn-hoved");
        });

        if (btns.length > 12){
            let two_id = getRandomInt(btns.length - 1)
            let span_two = document.createElement('span');
            span_two.className = "btn-insect";
            btns[two_id].append(span_two);
            btns[two_id].classList.add("btn-animated");
            btns[two_id].addEventListener('mouseover', function () {
                btns[two_id].classList.add("btn-hoved");
            });
        }
    }
});
